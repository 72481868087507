import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import Layout from "../components/layout";
import ReviewCard from "../components/review-card";
import ReviewCardCt from "../components/review-card-ct";
import moment from "moment";
const Reviews = (props) => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const [reviewsTotal, setReviewsTotal] = useState([]);
	var reviews = [];
	var reviewsCt = [];
	var totalReviews = [];

	var num = 0;

	useEffect(() => {
		axios
			.get(
				`https://www.local-marketing-reports.com/external/showcase-reviews/widgets/ad64298d128005102590e912febcefed2cc204d3`
			)
			.then((res) => {
				document.getElementById("loading-spinner").style.display = "none";
				document.getElementById("no-reviews").style.display = "block";
				const reviewData = res.data.results;
				reviews = reviewData;
				handleReviewsFirst();
			});
	}, [num]);

	const handleReviewsFirst = () => {
		totalReviews = totalReviews.concat(
			reviews.map((review) => ({
				reviewBody: review.reviewBody,
				datePublished: new Date(review.datePublished),
				author: review.author,
				source: review.source,
			}))
		);
		setReviewsTotal(
			totalReviews.sort((a, b) => b.datePublished - a.datePublished)
		);
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Reviews",
				item: {
					url: `${siteUrl}/reviews`,
					id: `${siteUrl}/reviews`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Reviews | Denis Bonnici Electricians Ltd"
				description="Denis Bonnici Electricians Ltd Reviews"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/reviews`,
					title: "Reviews | Denis Bonnici Electricians Ltd",
					description: "Denis Bonnici Electricians Ltd Reviews",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@denisbonnici",
					site: "@denisbonnici",
					cardType: "summary",
				}}
			/>
			<Container className="mt-24 mb-14 py-5" as="section">
				<Row className="justify-content-center">
					<Col xs={11}>
						<Row className="mb-20">
							<Col className="d-flex justify-content-center" xs={12}>
								<h1 className="text-3xl mb-3 fw-700 lato text-center">
									Our Customer Reviews
								</h1>
							</Col>
							<Col xs={12}>
								<p
									className="text-center"
									id="no-reviews"
									style={{ display: "none" }}
								>
									<em>Rated 5 / 5 across {reviewsTotal.length} Reviews</em>
								</p>
							</Col>
							<Col className="d-flex justify-content-center" xs={12}>
								<span className="sl-underline d-block" />
							</Col>
							<Col className="pt-3 text-center">
								<p>
									We always welcome a review from our customers! <br />
									<a
										href="https://www.checkatrade.com/give-feedback/trades/denisbonnicielectricians"
										target="_blank"
										rel="noreferrer"
									>
										Click here to leave your Review
									</a>
								</p>
							</Col>
						</Row>
						<Row className="text-center"></Row>

						<Row className="justify-content-center">
							<Spinner
								id="loading-spinner"
								variant="primary"
								animation="border"
							></Spinner>
							<Row>
								{reviewsTotal.map((review) => (
									<Col lg={4} md={6} xs={12}>
										{review.source !== null && <ReviewCard review={review} />}
										{review.source === null && <ReviewCardCt review={review} />}
									</Col>
								))}
							</Row>
						</Row>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default Reviews;
